import React from "react";

function Footer() {
  return (
    <div className="Footer container-fluid">
      <div className='Copyright'>&#169; 2021 Pubbliufficio</div>
    </div>
  );
}

export default Footer;
